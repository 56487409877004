<template>
  <!-- Add Graduate Work Theme Modal -->
  <div class="modal fade" id="addGraduateWorkThemeModal" tabindex="-1"
       aria-labelledby="addGraduateWorkThemeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addGraduateWorkThemeModalLabel">Добавление</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div class="col-md-12 mt-4">


            <div class="form-group row mt-4">
              <label for="study_level" class="col-md-3 col-form-label">Уровень
                обучения</label>
              <div class="col-md-9" id="study_level">
                <select class="form-control form-select"
                        @input="changeStudyLevel($event)">
                  <option v-for="(item, index) in [{id: 0, name: 'Выберите уровень обучения'}, ...graduateWorkTheme_form.studyLevel]"
                          :value="item.id"
                          :key="index">{{ item.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row mt-4" v-if="selectedStudyLevelId != null">
              <label for="education_program" class="col-md-3 col-form-label">Образовательная программа</label>
              <div class="col-md-9" id="education_program">


                <DropdownListEducationProgramGraduateWorkTheme
                    :options="options"
                    :value="item"
                    @change="changeSelectEducationProgramData"
                    @search="onSearch"/>
<!--                <select class="form-control form-select"-->
<!--                        @input="changeNewGraduateWorkThemeData('education_speciality_code', $event)">-->
<!--                  <option v-for="(item, index) in graduateWorkTheme_form.educationProgram"-->
<!--                          :value="item.id"-->
<!--                          :key="index">{{ item.name }}-->
<!--                  </option>-->
<!--                </select>-->
              </div>
            </div>

            <div class="form-group row mt-4">
              <label for="theme_name_kz" class="col-md-3 col-form-label">Название
                темы на казахском</label>
              <div class="col-md-9" id="theme_name_kz">
                      <textarea class="form-control" placeholder="Название темы"
                                @change="changeNewGraduateWorkThemeData('theme_name_kz', $event)">
                      </textarea>
              </div>
            </div>

            <div class="form-group row mt-4">
              <label for="theme_name_ru" class="col-md-3 col-form-label">Название
                темы на русском</label>
              <div class="col-md-9" id="theme_name_ru">
                      <textarea class="form-control" placeholder="Название темы"
                                @change="changeNewGraduateWorkThemeData('theme_name_ru', $event)">
                      </textarea>
              </div>
            </div>

            <div class="form-group row mt-4">
              <label for="theme_name_en" class="col-md-3 col-form-label">Название
                темы на английском</label>
              <div class="col-md-9" id="theme_name_en">
                      <textarea class="form-control" placeholder="Название темы"
                                @change="changeNewGraduateWorkThemeData('theme_name_en', $event)">
                      </textarea>
              </div>
            </div>



          </div>


        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  @click="submitGraduateWorkTheme">
            Добавить
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeAddGraduateWorkThemeModal">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Add Syllabus Template Content Modal -->
</template>


<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import DropdownListEducationProgramGraduateWorkTheme from "@/components/common/DropdownListEducationProgramGraduateWorkTheme.vue";
export default {
  name: "AddGraduateWorkThemeModal",
  components: {
    DropdownListEducationProgramGraduateWorkTheme,
  },
  data() {
    return {
      options: [],
      selectedStudyLevelId: null,
      educationProgram: null
    }
  },
  computed: {
    ...mapState('graduateWorkTheme', ['graduateWorkTheme_form']),
  },

  methods: {
    ...mapActions('graduateWorkTheme', ['GET_STUDY_LEVEL_NAMES', 'GET_EDUCATION_PROGRAMS_BY_NAME_GRADUATE_WORK','POST_GRADUATE_WORK_THEME_DATA','GET_GRADUATE_WORK_THEME_DATA']),
    ...mapMutations('graduateWorkTheme', ['SET_SELECTED_EDUCATION_PROGRAM_SPECIALITY_CODE', 'SET_NEW_GRADUATE_WORK_THEME_DATA', 'SET_NEW_GRADUATE_WORK_THEME_STUDY_LEVEL_ID']),
    onSearch(search) {
      if (search.length > 3) {
        this.GET_EDUCATION_PROGRAMS_BY_NAME_GRADUATE_WORK({name: search, study_level_id: this.selectedStudyLevelId}).then(json => (this.options = json));
      }
    },

    changeSelectEducationProgramData(educationProgram) {
      console.log(educationProgram.education_speciality_code, 'education_speciality_code')
      this.educationProgram = educationProgram
      this.SET_SELECTED_EDUCATION_PROGRAM_SPECIALITY_CODE(educationProgram.education_speciality_code)


    },

    changeNewGraduateWorkThemeData(property, e, val = 'value') {
      const value = e.target[val]
      this.SET_NEW_GRADUATE_WORK_THEME_DATA({property, value})
    },
    changeStudyLevel(e) {
      const value = e.target.value
      this.selectedStudyLevelId = value
      console.log(this.selectedStudyLevelId, "selectedStudyLevelId")
      this.SET_NEW_GRADUATE_WORK_THEME_STUDY_LEVEL_ID(this.selectedStudyLevelId)



    },

    async submitGraduateWorkTheme() {
      await this.POST_GRADUATE_WORK_THEME_DATA().then(res => {
        if (res) {
          this.GET_GRADUATE_WORK_THEME_DATA()

          this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
        } else {
          const errorText = res.errors[0].map(err => err.message).join('\n');
          this.$error({title: 'Добавление', text: errorText})
        }
      })
    },
    closeAddGraduateWorkThemeModal() {
      this.graduateWorkTheme_form.newGraduateWorkThemeInfos = {}
    }

  },
  async mounted() {
    this.GET_STUDY_LEVEL_NAMES()
  }
}
</script>

<style scoped>

</style>