<template>
  <div class="container">

    <div class="graduate-work-theme mt-4 mb-4">
      <h4 class="text-center mb-4">Темы для дипломных работ</h4>

      <AddGraduateWorkThemeModal/>

      <div class="row mb-4">
        <div class="col-md-12">
          <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                  data-bs-target="#addGraduateWorkThemeModal">
            <i class="fas fa-plus-circle" aria-hidden="true"></i>&nbsp;Добавить тему дипломной работы
          </button>
        </div>
      </div>

      <DataTable :value="graduateWorkTheme_form.graduateWorkThemeInfos" :paginator="true" :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                 stripedRows responsiveLayout="scroll">

        <Column field="theme_name_kz" header="Название темы на казахском"></Column>
        <Column field="theme_name_ru" header="Название темы на русском"></Column>
        <Column field="theme_name_en" header="Название темы на английском"></Column>
        <Column field="study_level_name" header="Уровень обучения"></Column>
        <Column field="academic_year_name" header="Учебный год"></Column>


      </DataTable>

    </div>
  </div>

  <!--  </div>-->
</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import AddGraduateWorkThemeModal from '@/components/graduate-work/teacher/AddGraduateWorkThemeModal.vue'
export default {
  name: "GraduateWorkTheme",
  components: {
    AddGraduateWorkThemeModal
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState('graduateWorkTheme', ['graduateWorkTheme_form']),
  },

  methods: {
    ...mapActions('graduateWorkTheme', ['GET_STUDY_LEVEL_NAMES', 'GET_GRADUATE_WORK_THEME_DATA']),
    ...mapMutations('graduateWorkTheme', ['']),


  },

  async mounted() {
    await this.GET_GRADUATE_WORK_THEME_DATA()
  }
}
</script>

<style scoped>

</style>
